// Variable overrides
$color-text: #515456;
$color-main: #20a8d8;
$primary-base:  #20a8d8;
$secondary-base: #c8ced3;
$color-error: rgba(197, 11, 11, 1);
$nav-link: #374750;
$sidebar-nav-icon-font-size: 0.875rem;
$sidebar-nav-icon-height: 1rem;
$sidebar-nav-link-padding-y: .75rem;
$sidebar-nav-link-padding-x: 1rem;
$sidebar-nav-title-margin-top: 0.25rem;
$sidebar-brand-bg: transparent;
$header-padding-x: 2rem;
$header-padding-y: 0rem;
$font-size-default: 14px;
$enable-deprecation-messages: false;
