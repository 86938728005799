.sk-fading-circle {
    width: 40px;
    height: 40px;
    position: relative;
}

.sk-fading-circle .sk-circle {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
}

.sk-fading-circle .sk-circle:before {
    content: '';
    display: block;
    margin: 0 auto;
    width: 15%;
    height: 15%;
    background-color: #333;
    border-radius: 100%;
    -webkit-animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
            animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
}
.sk-fading-circle .sk-circle2 {
    -webkit-transform: rotate(30deg);
        -ms-transform: rotate(30deg);
            transform: rotate(30deg);
}
.sk-fading-circle .sk-circle3 {
    -webkit-transform: rotate(60deg);
        -ms-transform: rotate(60deg);
            transform: rotate(60deg);
}
.sk-fading-circle .sk-circle4 {
    -webkit-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
            transform: rotate(90deg);
}
.sk-fading-circle .sk-circle5 {
    -webkit-transform: rotate(120deg);
        -ms-transform: rotate(120deg);
            transform: rotate(120deg);
}
.sk-fading-circle .sk-circle6 {
    -webkit-transform: rotate(150deg);
        -ms-transform: rotate(150deg);
            transform: rotate(150deg);
}
.sk-fading-circle .sk-circle7 {
    -webkit-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
            transform: rotate(180deg);
}
.sk-fading-circle .sk-circle8 {
    -webkit-transform: rotate(210deg);
        -ms-transform: rotate(210deg);
            transform: rotate(210deg);
}
.sk-fading-circle .sk-circle9 {
    -webkit-transform: rotate(240deg);
        -ms-transform: rotate(240deg);
            transform: rotate(240deg);
}
.sk-fading-circle .sk-circle10 {
    -webkit-transform: rotate(270deg);
        -ms-transform: rotate(270deg);
            transform: rotate(270deg);
}
.sk-fading-circle .sk-circle11 {
    -webkit-transform: rotate(300deg);
        -ms-transform: rotate(300deg);
            transform: rotate(300deg); 
}
.sk-fading-circle .sk-circle12 {
    -webkit-transform: rotate(330deg);
        -ms-transform: rotate(330deg);
            transform: rotate(330deg); 
}
.sk-fading-circle .sk-circle2:before {
    -webkit-animation-delay: -1.1s;
            animation-delay: -1.1s; 
}
.sk-fading-circle .sk-circle3:before {
    -webkit-animation-delay: -1s;
            animation-delay: -1s; 
}
.sk-fading-circle .sk-circle4:before {
    -webkit-animation-delay: -0.9s;
            animation-delay: -0.9s; 
}
.sk-fading-circle .sk-circle5:before {
-webkit-animation-delay: -0.8s;
        animation-delay: -0.8s; 
}
.sk-fading-circle .sk-circle6:before {
-webkit-animation-delay: -0.7s;
        animation-delay: -0.7s; 
}
.sk-fading-circle .sk-circle7:before {
-webkit-animation-delay: -0.6s;
        animation-delay: -0.6s; 
}
.sk-fading-circle .sk-circle8:before {
-webkit-animation-delay: -0.5s;
        animation-delay: -0.5s; 
}
.sk-fading-circle .sk-circle9:before {
-webkit-animation-delay: -0.4s;
        animation-delay: -0.4s;
}
.sk-fading-circle .sk-circle10:before {
-webkit-animation-delay: -0.3s;
        animation-delay: -0.3s;
}
.sk-fading-circle .sk-circle11:before {
-webkit-animation-delay: -0.2s;
        animation-delay: -0.2s;
}
.sk-fading-circle .sk-circle12:before {
-webkit-animation-delay: -0.1s;
        animation-delay: -0.1s;
}

@-webkit-keyframes sk-circleFadeDelay {
0%, 39%, 100% { opacity: 0; }
40% { opacity: 1; }
}

@keyframes sk-circleFadeDelay {
0%, 39%, 100% { opacity: 0; }
40% { opacity: 1; } 
}

.dot-loader{
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background-color: #333;
    position: relative;
    -webkit-animation: 1.2s scaleDown ease-in-out infinite ;
    animation: 1.2s scaleDown ease-in-out infinite ;
}

.dot-loader:nth-child(2){
    margin: 0 7px;
    -webkit-animation: 1.2s scaleDown ease-in-out infinite .15555s;
    animation: 1.2s scaleDown ease-in-out infinite .15555s;
}

.dot-loader:nth-child(3){
    -webkit-animation: 1.2s scaleDown ease-in-out infinite .300000s;
    animation: 1.2s scaleDown ease-in-out infinite .300000s;
}

@-webkit-keyframes scaleDown{
    0%, 80%, 100%{
      -webkit-transform: scale(0);
              transform: scale(0);
    }
    40%{
      -webkit-transform: scale(1);
              transform: scale(1);
    }
}

@keyframes scaleDown{
    0%, 80%, 100%{
      -webkit-transform: scale(0);
              transform: scale(0);
    }
    40%{
        -webkit-transform: scale(1);
                transform: scale(1);
    }
}

.loader {
    display: flex;
    position: relative;
    width: 100vw;
    height: 100vh;
    justify-content: center;
    align-items: center;
    &:after {
        content: " ";
        display: block;
        border-radius: 50%;
        width: 0;
        height: 0;
        margin: 8px;
        box-sizing: border-box;
        border: 32px solid #41B5DE;
        border-color: #41B5DE transparent #41B5DE transparent;
        animation: lds-hourglass 1.2s infinite;
    }
}
@keyframes lds-hourglass {
    0% {
      transform: rotate(0);
      animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }
    50% {
      transform: rotate(900deg);
      animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    100% {
      transform: rotate(1800deg);
    }
}
  