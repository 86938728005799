.app {
  background-color: #e4e5e6;
}
body{
  background-color: #ffffff;
}
.toffs-logo {
  width: 80px;
  margin: 8px;
}
.toffs-logo-cn {
  width: 126px;
  margin: 8px;
}
.whitelabel-img {
  width: 193px;
}
.choose-plan-row {
  width: 80%;
  margin: 30px auto;
}
header.app-header {
  border-bottom: 1px solid #e4eaef;
}
footer {
  border-top: 1px solid #e4eaef;
  .footer-list {
    .title {
      font-weight: bold;
    }
    li {
      list-style: none;
    }
  }
}
// Here you can add other styles
//Start style for Rate Limit
.button-form-control {
  height: calc(1.5em + 0.75rem + 5px);
}
//End style for Rate Limit
// Style for Add Domain
.step-header {
  .step-title {
    font-size: 24px;
    font-weight: 600;
    color: #7f7e7f;
  }

  .step-info {
    line-height: 1em;
    position: relative;
    outline: 0;
    border: 0;
    color: #efefef;
    text-align: center;
    height: 1.5em;
    font-weight: lighter;
    font-size: 16px;
    opacity: 0.5;

    &:before {
      content: "";
      background: #ddd;
      position: absolute;
      left: 0;
      top: 50%;
      width: 100%;
      height: 1px;
    }

    &:after {
      content: attr(step-info);
      position: relative;
      display: inline-block;
      padding: 0 0.5em;
      line-height: 1.5em;
      background-color: #efefef;
      color: #626562;
    }
  }
}

.plan-card-column {
  margin-right: -0.4rem;
  margin-left: 0.2rem;
  margin-bottom: 20px;
  a {
    text-decoration: none;
    color: #4a4a4a;
    font-weight: 400;
  }
}

.setup-domain-redirect {
  &:hover {
    //color: #20a8d8;
    text-decoration: none;
  }
  cursor: pointer;
}
h2.price {
  font-size: 18px;
}
.payment.button-confirm {
  width: 120px;
}
.card-plan {
  &:hover {
    text-decoration: none;
  }
  background-color: #fff;
  box-shadow: 0 8px 10px rgba(163, 177, 198, 0.6), -8px -8px 16px rgba(255, 255, 255, 0.5);
  width: 100%;
  .card-head {
    .card-title {
      font-size: 20px;
      color: #fff;
    }
  }

  .price-section {
    position: relative;
    min-height: 86px;
    .price {
      font-size: 26px;
    }
    .desciption {
      font-size: 12px;
      padding-left: 15px;
      padding-right: 15px;
      min-height: 75px;
    }
    .long-text {
      min-height: 90px;
    }
    .short-text {
      min-height: 50px;
    }
  }

  .sales-info {
    width: 94%;
    margin: 30px 3%;
    border: none;
    border-top: 1px solid #d8d8d8;
  }

  .sales-label {
    font-size: 14px;
    color: #fff;
    border-radius: 2px;
    text-align: center;
    width: 86%;
    left: 7%;
    bottom: 20px;
    &.sale-active {
      cursor: pointer;
    }
    p {
      padding: 5px 5px;
    }
  }

  .features {
    min-height: 400px;
    ul {
      list-style: none;
      padding-left: 0;
      overflow-y: auto;
      width: 100%;
      margin-bottom: 0;
      li {
        margin-left: 0;
        color: #858485;
        font-size: 12px;
      }
    }
  }
}
//general for medal account plan
$plan-basic: #139cec;
$plan-professional: #f26922;
$plan-premium: #ee2f47;
$plan-enterprise: #6bad45;
.basic-medal {
  color: $plan-basic;
}

.professional-medal {
  color: $plan-professional;
}

.premium-medal {
  color: $plan-premium;
}

.enterprise-medal {
  color: $plan-enterprise;
}
.basic-plan {
  .card-head,
  .sales-label {
    background-color: $plan-basic;
  }
}

.professional-plan {
  .card-head,
  .sales-label {
    background-color: $plan-professional;
  }
}

.premium-plan {
  .card-head,
  .sales-label {
    background-color: $plan-premium;
  }
}

.enterprise-plan {
  .card-head,
  .sales-label {
    background-color: $plan-enterprise;
  }
}

.setup-domain {
  .plan-name {
    &.price {
      font-size: 18px;
    }
  }
  .plan-select {
    margin-top: -5px;
    width: 150px;
  }
}

.exceeded-content {
  min-height: 400px;
  background-color: #fff;
}

.font-size-18 {
  font-size: 18px;
}

.font-size-12 {
  font-size: 12px;
}

.domain-card {
  .card-body {
    background-color: #e4e5e6;
  }
}
.domain-row {
  margin-bottom: 20px;
  background-color: #e3e3e3;
  border-radius: 4px;
  .btn-action-table {
    border-top-right-radius: 4px;
    top: 0;
    right: 0;
    width: 38px;
    height: 38px;
  }
  .check-cname {
    button {
      margin-top: -8px;
      border-radius: 0;
      font-weight: normal;
      margin-left: 5px;
      padding-left: 5px;
      padding-right: 5px;
    }
  }

  .position-btns {
    right: 0;
    top: 0
  }

  .btn-action-edit {
    border-radius: 0px;
    width: 38px;
    height: 38px;
  }

  .btn-action-report {
    background-color: #3cb765cf;
    border: none;
    &:hover {
      background-color: #2c8b4ccf;
    }
  }

  .btn-action-waf-report {
    background-color: #06a783;
    border: none;
    &:hover {
      background-color: #077a61;
    }
  }
  @media only screen and (min-width: 1280px) {
    .cname-content {
        min-width: 650px;
    }
  }
}
.btn-public {
  background: #ea868f;
  &:hover {
    background: rgb(230, 131, 137);
  }
}
// End Style for Manage Domain
// Start Style for Pulsalting Dot
.holder {
  width: 15px;
  height: 15px;
  position: relative;
  .pulse {
    width: 6px;
    height: 6px;
    border: 2px solid #7ed321;
    border-radius: 50%;
    background-color: #7ed321;
    z-index: 10;
    position: absolute;
    left: calc(8px / 2);
    top: calc(8px / 2);
  }
  .dot {
    border: 5px solid #7ed321;
    background: transparent;
    border-radius: 60px;
    height: 14px;
    width: 14px;
    -webkit-animation: pulse 2s ease-out;
    animation: pulse 2s ease-out;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    position: absolute;
    z-index: 1;
    opacity: 0;
  }
  .pulse-stopped {
    border: 2px solid red;
    background-color: red;
  }
  .dot-stopped {
    border: 5px solid red;
  }
  .dot-pending {
    border: 2px solid #f0ad4e;
    background-color: #f0ad4e;
  }
  .pulse-pending {
    border: 5px solid #f0ad4e;
  }
  @keyframes pulse {
    0% {
      -moz-transform: scale(0);
      opacity: 0;
    }
    25% {
      -moz-transform: scale(0);
      opacity: 0.1;
    }
    50% {
      -moz-transform: scale(0.1);
      opacity: 0.3;
    }
    75% {
      -moz-transform: scale(0.5);
      opacity: 0.5;
    }
    100% {
      -moz-transform: scale(1);
      opacity: 0;
    }
  }
}
//End Style for Pulsalting Dot
//Start Style for Payment
.button-payment {
  display: block;
  width: 100%;
  height: 37px;
  background-color: #20a8d8;
  border-radius: 3px;
  font-weight: 600;
  color: #fff;
  cursor: pointer;
}

.custom-card-element {
  .__PrivateStripeElement {
    flex: 1;
  }
}

.payment-domain-info {
  ul {
    list-style: none;
  }
  .card-body {
    min-height: 164px;
  }
  span {
    font-weight: 600;
  }
}
//End Style for Payment

.bg-spin {
  width: 100vw;
  height: 100vh;
  background: rgba(204, 204, 204, 0.5);
  top: 0;
  left: 0;
  z-index: 99999;
}

//Start Style for Spin Table
.spin-table {
  position: absolute;
  top: calc(100vh / 2 - 180px);
  left: 50%;
}
//End Style for Spin Table

//Start style for Advance Configuration
.advance-configuration {
  min-height: 490px;
  .domain-dropdown {
    width: 400px;
    z-index: 2;
  }

  .upgrade-required {
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    background-color: rgba($color: #fff, $alpha: 0.7);
    .lock-icon {
      color: rgba(32, 168, 216, 1);
    }
    a {
      font-size: 20px;
    }
  }

  .upgrade-user {
    a {
      font-size: 14px;
    }
  }
}

.cache-extentions {
  .react-tagsinput-tag {
    margin-bottom: 4px;
    margin-right: 7px;
  }
}
//make general card
.general-card {
  border: 1px solid #efefef;
  .card-header {
    border-bottom: 1px solid #eff2f4;
    background-color: #efefef;
  }
  > .card-body,
  .card-step {
    background-color: #efefef;
  }
}

.custom-card{
  background-color: #efefef;
}

//make custom general tab
.general-tab {
  .card-body {
    padding: 25px 0 0 0;
  }
  .parent-tab {
    min-height: 370px;
  }
  .tab-content {
    background-color: #fff;
    .tab-pane {
      padding: 1rem;
    }
    .children-tab {
      border-top: 0.5px solid #c8ced3;
    }
    .nav-tabs {
      border: none;
      .nav-item {
        .active {
          border-bottom: 3px solid #29bcea;
          &:hover {
            border-bottom: 3px solid #29bcea;
          }
        }
      }
    }
  }
  .nav-tabs {
    border: none;
    .nav-item {
      text-align: center;
      .parent-tab-link {
        height: 50px;
        justify-content: center;
        display: flex;
        flex-direction: column;
        cursor: pointer;
      }
      .nav-link {
        border: none;
        color: #374750;
        &:hover {
          color: #6dc4df;
        }
      }
      .active {
        border: none;
        color: #20a8d8;
        &:hover {
          color: #20a8d8;
        }
      }
    }
  }
}
// Start style for payment method

.payment-creditcard,
.payment-alipay,
.payment-wechat,
.payment-unionpay {
  width: 200px;
}
.reuse-card {
  width: 30%;
}
.payment-option {
  .payment-radio-button{
    position: relative;
    width: 250px;
  }
  .payment-checked-icon{
    position: absolute;
    z-index: 1;
    color: #008000;
    right: 0;
  }
  .custom-radio .form-check-input:checked ~ .form-check-label-icon:after {
    box-shadow: -1px 0 5px rgba(163,154,212,.7), -1px 0 1px #c7ddda;
    border: 4px solid #fff;
  }
  .form-check-label {
    display: none;
  }
  .form-check-label-icon {
    cursor: pointer;
  }
  .form-check-input:checked ~ .form-check-label-icon::after {
    display: block;
    width: 300px;
    height: 82px;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
  }
}
// End style for payment method
.account-subscription {
  .fa-medal {
    margin-left: -8px;
  }
}

.drop-account {
  margin-left: -8px;
  margin-right: 8px;
}

.invoice {
  .amount-info {
    box-shadow: inset 0 -1px #e3e8ee;
    .invoice-amount {
      font-size: 20px;
      font-weight: 500;
    }
    .badge {
      font-size: 13px;
      vertical-align: super;
    }
  }
  .payment-info {
    .text-label {
      font-size: 16px;
      color: #697386;
    }
    .right-box-shadow {
      box-shadow: inset -1px 0 #e3e8ee;
    }
    .credit-card {
      margin-top: -10px;
    }
  }
}

.react-tel-input {
  .form-control {
    border: 1px solid #e4e7ea !important;
  }
  .flag-dropdown{
    border: 1px solid #e4e7ea !important;
  }
}

.configuration-advance {
  background-color: #f2f4f5;
}

.btn-primary:not(:disabled):not(.disabled).active.button-advance, button.btn-primary.button-advance{
  bottom: 5px;
  right: 59px;
  cursor: pointer;
  &.active{
    color: #fff;
    background-color: #1985ac;
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
    outline: 0;
    color: #c3c3c3;
  }
}

.spin-check-domain {
  top: 10.5px;
  right: 5px;
}
.resend-email-verify {
  font-size: 14px;
}

.singpass-qr {
  #qr_wrapper {
    > div {
      background-color: transparent;
    }
  }
}

.origin-port > label.error-custom {
  min-width: 115px;
}

.exhibition {
  height: 95%;
  .features {
    min-height: 310px;
    ul {
      li {
        font-size: 13px;
      }
    }
  }
}

.ssl-add {
  .input-focus-no-border {
    &:focus {
      outline: none;
    }
  }

  .p-small {
    font-size: 14px;
    color: #828283;
  }

  .p-big {
    font-size: 20px;
    color: #161616;
  }
}

.verify-domain {
  .file-preview {
    background: #f7f7f7;
    position: relative;
    font-size: 0.833333333em;
    padding: 1.6em 2.9333em 1.6em 1.6em;
    .paper-fold {
      position: absolute;
      width: 2em;
      height: 2em;
      border-left: solid #d9d9d9 1px;
      border-bottom: solid #d9d9d9 1px;
      right: 0;
      top: 0;
      background: linear-gradient( 45deg, #f7f7f7 0%, #f7f7f7 50%, #ffffff 50%, #ffffff 100% );
    }
  }
}

.header-nav {
  height: 40px;
  border-bottom: 1px solid #ccc;

  svg {
    color: #20a8d8;
  }
}

.accordion-dns {
  .accordion-item {
    border: 0;
  }
  .collapse.show {
    background-color: #efefef;
  }
  .accordion-header {
    background-color: #efefef;
    min-height: 55px;
    .title {
      border: none;
      background: none;
      font-size: 18px;
      color: #515456;
      &.collapsed{
        svg {
          -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg)
        }
      }
    }
    .action {
      border: 1px solid $color-main;
      button {
        border: 0;
        border-right: 1px solid $color-main;
        font-size: 14px;
        line-height: 40px;
        width: 140px;
        &:last-child {
          border-right: none;
        }
        &:hover,&:active {
          background-color: $color-main;
          color: #fff;
        }
      }
    }
    .action-button {
      button {
        border: 0;
        border-right: 1px solid $color-main;
        font-size: 14px;
        line-height: 40px;
        width: 140px;
        margin-left: 5px;
        background-color: $color-main;
        color: #fff;
        &:last-child {
          border-right: none;
        }
        &.btn-dns{
          width: max-content;
          padding: 0px 15px;
          max-height: 40px;
        }
      }
      .delete {
        background-color: $color-error;
        color: #fff;
      }
      .delete:disabled, .delete[disabled] {
        color: #515456;
        background-color: #CCC;
      }
    }
  }
}

.btn-delete {
  background-color: $color-error;
  color: #fff;
}

.modal-add {
  .modal-header {
    .close {
      background-color: transparent;
      border: 0;
      font-size: 22px;
    }
  }

  .modal-zone{
    overflow: visible ;
    overflow-y: visible ;
  }

  .modal-dns{
    max-height: calc(100vh - 240px);
    overflow: hidden;
    overflow-y: auto;
  }

  .modal-cdn{
    max-height: calc(100vh - 240px);
    overflow: hidden;
    overflow-y: hidden;
  }
}

.modal-download{
  .modal-traceroute{
    overflow: visible ;
    overflow-y: visible ;
    height : 230px;
  }
}

.drap-drop {
  .item-record {
    background-color: #e4e4e4;
  }
}

.icon-color {
  color: #515456;
}

.accordion-add-cdn {
  .collapse.show {
    background-color: #fff;
  }
  .accordion-header {
    background-color: #fff;
  }

  .accordion-item {
    border-bottom: 1px solid #e6e6e6;
  }
}

.bg-add-cdn {
  background: #e6e6e6;
}
.popover {
  min-width: 450px;
}

.fs-defaut {
  font-size: $font-size-default;
}

.check-delete {
  width: 50px;
}

.icon-nav {
  width: 23px;
}

i.fad {
  --fa-secondary-color: #7a7a7a;
  --fa-primary-color: #3e3e3e;
  --fa-secondary-opacity: 0.6;
  --fa-primary-opacity: 0.8;
}
i.fad.enterprise-medal {
  --fa-secondary-color: #565654;
  --fa-primary-color: #6bad45;
  --fa-secondary-opacity: 0.6;
  --fa-primary-opacity: 1;
}
i.fad.basic-medal {
  --fa-secondary-color: #565654;
  --fa-primary-color: #139cec;
  --fa-secondary-opacity: 0.6;
  --fa-primary-opacity: 1;
}
i.fad.premium-medal {
  --fa-secondary-color: #565654;
  --fa-primary-color: #ee2f47;
  --fa-secondary-opacity: 0.6;
  --fa-primary-opacity: 1;
}

.select-primary {
  min-width: 250px;
}
.header-breadcrumb {
  font-size: 10px;
  font-weight: bold;
}

.react-select-copy {
  right: 50px;
  cursor: pointer;
  z-index: 1;
}

.txt-host{
  margin-right: 20px;
}

.wfit-content {
  width: fit-content;
}

.max-height-chart {
  max-height: 400px;
}

.flex-1 {
  flex: 1;
}

.block-color{
  width: 5px;
  margin: -4px 10px -4px -4px;
}

.bg-header-bottom{
  min-width: 15px;
  min-height: 5px;
  margin: 0px -3px -6px;
}

.button-width {
  width: 35px;
}

.DateRangePicker {
  width: 100%;
  // max-width: 288px;
}

.DateRangePickerInput.DateRangePickerInput__withBorder{
  width: 100%;
  height: 38px;
  border-radius: 4px;
  border: 1px solid #b5b5b5cc;
  display: flex;
  align-items: center;
  justify-content: flex-start;

}

.DateInput_input.DateInput_input_1 {
  font-size: 15px;
  padding: 3px 0px 0px 15px;
  margin-bottom: 0px;
}

.date-time-error .DateRangePickerInput__withBorder{
  border: 1px solid #d22828cc !important;
}

.DayPicker_focusRegion.DayPicker_focusRegion_1 {
  z-index: 9999;
  background: #fff;
}

.search-status {
  min-width: 150px;
  &> div {
    border-radius: 0;
  }
}

.width-download {
  max-width: 150px;
    border-radius: 0px;
}

.w-300 {
  width: 500px;
}

.break-line{
  word-break: break-all;
}

.button-origin {
  max-height: 38px;
}

.wmax-500 {
  max-width: 500px;
}

.wmax-200 {
  max-width: 200px;
}


.filter-btn {
  height: 37.5px;
  margin-top: 0px;
  border-radius: 4px;
}

.status-btn{
  width: 70px;
}

.expand-table {
  background-color: #f3f3f3 !important;
}

.color-background{
  background-color: white;
  color: #515456;
  font-weight: bolder;
}

.add-button{
  padding-top: 28px;
  // width: 44px;
  max-height: 67px;
}

.drop-down{
  height: 40px;
  border-radius: 0;
  border: $color-main;
  background-color: $color-main;
  color: white;
  &:hover{
    background-color: $color-main;
    color: white;
    border: none;
  }
}

.w-table {
  width: 150px;
}
.w-file-Name{
  max-width: 400px;
}

.w-check {
  width: 70px;
}

// .w-small {
   // width: 50px !important;
// }

.mh-table {
  min-height: 30px;
}

.w-value {
  width: 38%;
}

.emergency{
  background-color: #fd7e14;
}

.plus-button {
  max-width: 130px;
}

.rct-collapse-btn{
  border: 0px;
  background-color: white;
  margin-right: 10px;
  width: 15px;
}

.rct-title{
  margin-left: 10px;
  font-size: large;
}

.react-checkbox-tree ol {
  list-style-type: none;
  padding-left: 30px;
  ol{
    padding-left: 20px;
    ol{
      padding-left: 40px;
    }
  }
}

.time-block {
  fill: #c4c4c4;
}
.time-dns {
  fill: #eb40e4;
}
.time-connect {
  fill: #f8e400;
}
.time-ssl {
  fill: #ffa734;
}
.time-send {
  fill: #3dce52;
}
.time-wait {
  fill: #f56963;
}
.time-load {
  fill: #5995d7;
}
.js-icon {
  background-color: var(--cui-card-color);
  color: #F5CA56;
  font-size: 18px;
}

.img-icon {
  background-color: var(--cui-card-color);
  color: #28A48C;
  font-size: 18px;
}

.css-icon {
  background-color: var(--cui-card-color);
  color: #5D9ED4;
  font-size: 18px;
}

.html-icon {
  background-color: var(--cui-card-color);
  color: #EB7240;
  font-size: 18px;
}

.file-icon {
  background-color: black;
  color: #827775;
  font-size: 18px;
}

.custom-boder-search{
  border-radius: 2px;
}

.color-background-brown{
  background-color: #545454;
}

i.fad.check-green {
  --fa-secondary-color: #6bad45;
  --fa-primary-color: #565654;
  --fa-secondary-opacity: 1;
  --fa-primary-opacity: 0.1;
}

i.btn-icon {
  color: #393939;
}

.custom-dualist {
  height: 400px;

  button {
    background-color: #20a8d8;
    border-color:  #41B5DE;
    &:hover{
      background-color: #41B5DE !important;
    }
    &:focus:not(:disabled),&:active:not(:disabled){
      background-color: #20a8d8;
      outline: none;
      border-color:  #41B5DE;
    }
  }
}
.popover-waterfall-screenshot {
  width: 800px;
  max-width: 800px;
  border: none;
  .popover-inner {
    width: 100%;
  }
  img {
    width: 100%;
    max-height: 100vh;
    border-radius: 5px;
  }
}
.map-chart {
  min-height: 500px;
}
.report-pdf {
  min-width: 1000px;
}

.modal-message{
  .modal-header{
    padding: 10px;
  }

  .modal-footer{
    // padding: 60px;
    justify-content: center;
    border: none;
    height: 60px;
    button{
      height: 40px;
      width: 80px;
    }
  }

  .error-icon {
    --fa-primary-color: rgb(255, 255, 255);
    --fa-secondary-color: rgb(229,83,83);
    --fa-secondary-opacity: 1;
  }

  .message-icon{
    --fa-primary-color: rgb(255, 255, 255);
    --fa-secondary-color: rgb(32,168,216);
    --fa-secondary-opacity: 1;
  }
}

.copy-link{
  border: 1px solid
}

.icon-green{
  color: green;
}

.copy-button {
  height: 15px;
  width: 15px;
}

.search-report{
  margin-top: 29px;
}

.content-report{
  margin-top: 300px;
}

.help-chart{
  right: 145px;
  top: 1px;
}

.danger-alert{
  background: #FF8188;
  width: 38px;
  height: 38px;
}

.warning-alert{
  background: #F1AC6F;
  width: 38px;
  height: 38px;
}

.hidden-component {
  z-index: -100;
  position: fixed;
}

.whitelabel {
  .customer-select {
    z-index: 2
  }
  .domain {
    z-index: 1
  }
}
.btn-singpass {
  background-color: #F4333D;
  color: #fff;
  &:hover {
    background-color: #d2252e;
    color: #fff;
  }
}

.custom-networ-viewer {
  button, label, th, td {
    color: var(--cui-table-color) !important;
    font-size: 12px;
  }
  table {
    th {
      background-color: #ddd;
    }
  }
  > div {
    position: initial;
  }
}
.label-cache {
  width: 180px;
}
.input-cache {
  width: 150px;
}
.width-fit {
  width: fit-content;
}