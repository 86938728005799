.tooltip-marker {
  display: inline-block;
  margin-right: 4px;
  border-radius: 10px;
  width: 10px;
  height: 10px;

  &.cache-request {
    background-color: black;
  }
}
